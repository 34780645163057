import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    getDay,
    getHours,
    getMonth,
    getTime,
    getYear,
    isAfter,
    isBefore,
    isWithinInterval,
    parse,
    set,
    startOfDay,
} from "date-fns";
import bridge from "@vkontakte/vk-bridge";

export const _declension = (value, words) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
};

export const _isMobile = () => {
    return window.innerWidth < 900 ? true : false;
};

export const _getDomain = () => {
    return window.location.hostname === "localhost" ||
        window.location.hostname === "192.168.2.48"
        ? "dev.foodninja.pro"
        : window.location.hostname;
};

export const _getPlatform = () => {
    return window.currentPlatform !== undefined
        ? window.currentPlatform
        : "site";
};

export const _getMobileType = () => {
    return window.mobileType !== undefined ? window.mobileType : "android";
};

export const _clone = (object) => {
    if (typeof object != "object") {
        return object;
    }
    if (!object) {
        return object;
    }
    var r = object instanceof Array ? [] : {};
    for (var i in object) {
        if (object.hasOwnProperty(i)) {
            r[i] = _clone(object[i]);
        }
    }
    return r;
};

export const _checkPromocode = ({
    promocode,
    promocodeProduct,
    items,
    cartTotal,
    config,
    typeDelivery,
    isInitial,
    categories,
    preorderDate,
    preorderTime,
}) => {
    if (Object.keys(promocode).length) {
        let status;
        let alert;
        let errors = [];

        const productsArray = Object.values(items);

        // Текущее время
        const currentTime = preorderDate || new Date();

        // Проверка даты
        if (promocode.endDateFormat && promocode.startDateFormat) {
            const promocodeStartDate = parse(
                promocode.startDateFormat,
                "dd.MM.yyyy",
                new Date()
            );

            const promocodeEndDate = parse(
                promocode.endDateFormat,
                "dd.MM.yyyy HH:mm",
                new Date()
            );

            if (
                isBefore(currentTime, promocodeStartDate) ||
                isAfter(currentTime, promocodeEndDate)
            ) {
                status = "error";
                alert = preorderDate
                    ? "Промокод отменен, т.к. в выбранное время не действует."
                    : "Промокод отменен, т.к. в текущее время не действует.";
                errors.push({
                    code: "expired",
                    message: preorderDate
                        ? "Промокод в выбранное время не действует."
                        : "Промокод в текущее время не действует.",
                });
            }
        }

        // Проверка дня рождения

        if (
            promocode.type === "birthday" &&
            promocode.birthdayDayLimitBefore &&
            promocode.birthdayDayLimitAfter
        ) {
            const currentDate = new Date();
            const backDate = new Date(currentTime);
            backDate.setDate(
                currentDate.getDate() - promocode.birthdayDayLimitBefore
            );
            const futureDate = new Date(currentTime);
            futureDate.setDate(
                currentDate.getDate() + promocode.birthdayDayLimitAfter
            );
            if (
                currentTime > futureDate.getTime() ||
                currentTime < backDate.getTime()
            ) {
                status = "error";
                alert = `Промокод отменен, т.к. действует только ${promocode.birthdayDayLimitBefore} день до и ${promocode.birthdayDayLimitAfter} день после дня рождения.`;
                errors.push({
                    code: "birthday",
                    message: `Промокод действует только ${promocode.birthdayDayLimitBefore} день до и ${promocode.birthdayDayLimitAfter} день после дня рождения.`,
                });
            }
        }

        // Проверка времени
        if (promocode.startTime && promocode.endTime) {
            const checkingTime = preorderTime || new Date();

            const isTimeAfterMidnight =
                parseInt(promocode.startTime.slice(0, 2)) >
                parseInt(promocode.endTime.slice(0, 2));

            const promocodeStartTime = set(checkingTime, {
                hours: parseInt(promocode.startTime.slice(0, 2)),
                minutes: parseInt(promocode.startTime.slice(3)),
                seconds: 0,
                milliseconds: 0,
            });
            const promocodeEndTime = set(checkingTime, {
                hours: isTimeAfterMidnight
                    ? 23
                    : parseInt(promocode.endTime.slice(0, 2)),
                minutes: isTimeAfterMidnight
                    ? 59
                    : parseInt(promocode.endTime.slice(3)),
                seconds: 0,
                milliseconds: 0,
            });

            const promocodeAfterMidnightEndTime = set(checkingTime, {
                hours: parseInt(promocode.endTime.slice(0, 2)),
                minutes: parseInt(promocode.endTime.slice(3)),
                seconds: 0,
                milliseconds: 0,
            });

            const isWithinPromocodeTime = () => {
                try {
                    return isWithinInterval(checkingTime, {
                        start: promocodeStartTime,
                        end: promocodeEndTime,
                    });
                } catch (error) {
                    console.log(
                        `${error.message}, Something wrong in promocode time interval`
                    );
                    return false;
                }
            };

            const isWithinAfterMidnightTime = () => {
                try {
                    return isWithinInterval(checkingTime, {
                        start: startOfDay(checkingTime),
                        end: promocodeAfterMidnightEndTime,
                    });
                } catch (error) {
                    console.log(
                        `${error.message}, Something wrong in promocode after midnight interval`
                    );
                    return false;
                }
            };

            const timeStatus =
                isWithinPromocodeTime() ||
                (isTimeAfterMidnight && isWithinAfterMidnightTime());

            if (!timeStatus) {
                status = "error";
                alert = preorderTime
                    ? "Промокод отменен, т.к. в выбранное время не действует."
                    : "Промокод отменен, т.к. в текущее время не действует.";
                errors.push({
                    code: "time",
                    message: preorderTime
                        ? "Промокод не действует в выбранное время"
                        : `Промокод действует с ${promocode.startTime} до ${promocode.endTime}`,
                });
            }
        }

        // Проверка дней недели
        if (promocode.days && promocode.days.length === 7) {
            if (preorderDate) {
                const preorderDayOfWeek =
                    getDay(preorderDate) === 0 ? 6 : getDay(preorderDate) - 1;
                const isPromocodeAvailable =
                    promocode.days[preorderDayOfWeek] === 1;
                if (!isPromocodeAvailable) {
                    status = "error";
                    alert =
                        "Промокод отменен, т.к. не действует в выбранный день недели";
                    errors.push({
                        code: "days",
                        message:
                            "Промокод не действует в выбранный день недели",
                    });
                }
            } else {
                const todayDayOfWeek =
                    getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;
                const isPromocodeAvailable =
                    promocode.days[todayDayOfWeek] === 1;
                if (!isPromocodeAvailable) {
                    status = "error";
                    alert = "Промокод отменен, т.к. не действует сегодня";
                    errors.push({
                        code: "days",
                        message: "Промокод не действует сегодня",
                    });
                }
            }
        }

        // Проверка типа доставки
        if (typeDelivery) {
            if (
                promocode.typeDelivery === "delivery" &&
                typeDelivery !== "delivery"
            ) {
                status = "error";
                alert = "Промокод отменен, т.к. действует только на доставку.";
                errors.push({
                    code: "delivery",
                    message: "Промокод действует только на доставку.",
                });
            }
            if (
                promocode.typeDelivery === "self" &&
                typeDelivery !== "self" &&
                typeDelivery !== "inRestoraunt"
            ) {
                status = "error";
                alert =
                    "Промокод отменен, т.к. действует только при самовывозе.";
                errors.push({
                    code: "selfDelivery",
                    message: "Промокод действует только при самовывозе.",
                });
            }
        }

        // Проверка платформы
        // if (promocode.platform) {
        //     const currentPlatform = _getPlatform();
        //     if (promocode.platform[currentPlatform] !== "on")
        //         return {
        //             status: "error",
        //             message:
        //                 "Промокод отменен, т.к. не действует на данной платформе.",
        //         };
        // }

        // Проверка категорий

        if (promocode.categories?.length) {
            if (promocode.categories_hardmode === "yes") {
                // Исключение категорий
                if (promocode.excludeCategories) {
                    let hasExcludeCategory = false;
                    productsArray.forEach((product) => {
                        const inCategories = product[
                            "items"
                        ][0].categories.filter((item) =>
                            promocode.categories.includes(item)
                        );
                        if (inCategories.length) hasExcludeCategory = true;
                    });

                    if (hasExcludeCategory) {
                        if (categories) {
                            const categoryNames = promocode.categories
                                ?.map((id) => {
                                    const category = categories?.find(
                                        (category) => category.term_id === id
                                    );
                                    if (category) {
                                        return `«${category.name}»`;
                                    }
                                })
                                .filter((el) => el);
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод не действует с товарами из категорий: ${categoryNames?.join(
                                    ", "
                                )}`,
                            });
                        } else {
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод действует с другими товарами.`,
                            });
                        }

                        status = "error";
                        alert =
                            "Промокод отменен, т.к. не действует с выбранными товарами.";
                    }
                }

                // Только указанные категории
                else {
                    let notInCategory = false;
                    productsArray.forEach((product) => {
                        const inCategories = product[
                            "items"
                        ][0].categories.filter((item) =>
                            promocode.categories.includes(item)
                        );
                        if (!inCategories.length) notInCategory = true;
                    });

                    if (notInCategory) {
                        if (categories) {
                            const categoryNames = promocode.categories
                                ?.map((id) => {
                                    const category = categories?.find(
                                        (category) => category.term_id === id
                                    );
                                    if (category) {
                                        return `«${category.name}»`;
                                    }
                                })
                                .filter((el) => el);
                            errors.push({
                                code: "onlyCategories",
                                message: `Промокод действует только с товарами из категорий: ${categoryNames?.join(
                                    ", "
                                )}`,
                            });
                        } else {
                            errors.push({
                                code: "onlyCategories",
                                message: `Промокод действует с другими товарами.`,
                            });
                        }
                        status = "error";
                        alert =
                            "Промокод отменен, т.к. действует с другими товарами.";
                    }
                }
            }
            // В мягком режиме проверяем, чтобы в корзине была хотя-бы одна категория из разрешенных
            else {
                if (promocode.excludeCategories) {
                    const availableProduct = productsArray.find((product) => {
                        return product.items[0].categories?.some(
                            (id) => !promocode.categories.includes(id)
                        );
                    });
                    if (!availableProduct) {
                        if (categories) {
                            const categoryNames = promocode.categories
                                ?.map((id) => {
                                    const category = categories?.find(
                                        (category) => category.term_id === id
                                    );
                                    if (category) {
                                        return `«${category.name}»`;
                                    }
                                })
                                .filter((el) => el);
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод действует, если в корзине есть товары из других категорий кроме: ${categoryNames?.join(
                                    ", "
                                )}`,
                            });
                        } else {
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод действует с другими товарами.`,
                            });
                        }
                        status = "error";
                        alert =
                            "Промокод отменен, т.к. действует с другими товарами.";
                    }
                } else {
                    const availableProduct = productsArray.find((product) => {
                        return product.items[0].categories?.some((id) =>
                            promocode.categories.includes(id)
                        );
                    });
                    if (!availableProduct) {
                        if (categories) {
                            const categoryNames = promocode.categories
                                ?.map((id) => {
                                    const category = categories?.find(
                                        (category) => category.term_id === id
                                    );
                                    if (category) {
                                        return `«${category.name}»`;
                                    }
                                })
                                .filter((el) => el);
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод действует с товарами из категорий: ${categoryNames?.join(
                                    ", "
                                )}`,
                            });
                        } else {
                            errors.push({
                                code: "excludeCategory",
                                message: `Промокод действует с другими товарами.`,
                            });
                        }
                        status = "error";
                        alert =
                            "Промокод отменен, т.к. действует с другими товарами.";
                    }
                }
            }
        }

        // Проверка товаров по скидке
        if (
            promocode.excludeSaleProduct &&
            promocode.categories_hardmode === "yes"
        ) {
            let hasSale = false;
            productsArray.forEach((product) => {
                if (
                    product["items"][0].options._sale_price &&
                    parseInt(product["items"][0].options._regular_price) >
                        parseInt(product["items"][0].options._sale_price)
                ) {
                    hasSale = true;
                }
            });
            const productWithSale = productsArray.find((el) => {
                if (
                    el.items[0].options._sale_price ||
                    el.items[0].variant?._sale_price
                ) {
                    return true;
                }
            });
            if (hasSale || productWithSale) {
                status = "error";
                alert =
                    "Промокод отменен, т.к. не действует с товарами по скидке.";
                errors.push({
                    code: "hasSale",
                    message: "Промокод не действует с товарами по скидке.",
                });
            }
        } else if (
            promocode.excludeSaleProduct &&
            promocode.categories_hardmode !== "yes"
        ) {
            const isOnlySaleProducts =
                productsArray.length &&
                productsArray.every(
                    (el) =>
                        el.items[0].options._sale_price ||
                        el.items[0].variant?._sale_price
                );

            if (isOnlySaleProducts) {
                status = "error";
                alert =
                    "Промокод отменен, т.к. не действует с товарами по скидке.";
                errors.push({
                    code: "onlySale",
                    message: "Промокод не действует с товарами по скидке.",
                });
            }
        }

        // Проверка минимальной суммы заказа
        const promocodeDeliveryMinPrice = parseInt(promocode.coupon_min_price);
        const promocodeSelfDeliveryMinPrice =
            promocode.coupon_selfdelivery_min_price === ""
                ? promocodeDeliveryMinPrice
                : parseInt(promocode.coupon_selfdelivery_min_price);

        const cartWithoutPromocodeProduct =
            promocode.type === "fixed_product" && promocodeProduct?.id
                ? cartTotal -
                  parseInt(promocode.promocodeProducts.options._price)
                : cartTotal;

        const deliveryAvailable =
            promocode.typeDelivery === "both" ||
            promocode.typeDelivery === "delivery";
        const selfDeliveryAvailable =
            promocode.typeDelivery === "both" ||
            promocode.typeDelivery === "self";

        if (
            promocodeDeliveryMinPrice > cartWithoutPromocodeProduct &&
            promocodeSelfDeliveryMinPrice > cartWithoutPromocodeProduct
        ) {
            status = "error";

            if (promocodeDeliveryMinPrice === promocodeSelfDeliveryMinPrice) {
                alert =
                    "Промокод отменен, т.к. действует при заказе на сумму от " +
                    promocodeDeliveryMinPrice +
                    " ₽";
                if (promocode.type === "fixed_product") {
                    errors.push({
                        code: "minPrice",
                        message:
                            "Минимальная сумма заказа без учёта товара по промокоду: " +
                            promocodeDeliveryMinPrice +
                            " ₽",
                    });
                } else {
                    errors.push({
                        code: "minPrice",
                        message:
                            "Минимальная сумма заказа c промокодом: " +
                            promocodeDeliveryMinPrice +
                            " ₽",
                    });
                }
            } else {
                let newMessage = "";

                if (promocode.type === "fixed_product") {
                    newMessage +=
                        "Минимальная сумма заказа без учёта товара по промокоду: ";
                } else {
                    newMessage += "Минимальная сумма заказа по промокоду: ";
                }

                if (deliveryAvailable && selfDeliveryAvailable) {
                    newMessage += `${promocodeDeliveryMinPrice} ₽ на доставку и ${promocodeSelfDeliveryMinPrice} ₽ на самовывоз`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму от 
                    ${promocodeDeliveryMinPrice} ₽. на доставку и от ${promocodeSelfDeliveryMinPrice} ₽ на самовывоз`;
                } else if (deliveryAvailable) {
                    newMessage += `${promocodeDeliveryMinPrice} ₽`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму от 
                    ${promocodeDeliveryMinPrice} ₽. на доставку`;
                } else if (selfDeliveryAvailable) {
                    newMessage += `${promocodeSelfDeliveryMinPrice} ₽`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму от ${promocodeSelfDeliveryMinPrice} ₽ на самовывоз`;
                }

                errors.push({
                    code: "minPrice",
                    message: newMessage,
                });
            }
        } else if (
            deliveryAvailable &&
            typeDelivery === "delivery" &&
            promocodeDeliveryMinPrice > cartWithoutPromocodeProduct
        ) {
            status = "error";
            alert = `Промокод отменен, т.к. действует при заказе на доставку на сумму от 
                    ${promocodeDeliveryMinPrice} ₽.`;
            if (promocode.type === "fixed_product") {
                errors.push({
                    code: "minPrice",
                    message: `Минимальная сумма заказа  на доставку без учёта товара по промокоду: ${promocodeDeliveryMinPrice} ₽`,
                });
            } else {
                errors.push({
                    code: "minPrice",
                    message: `Минимальная сумма заказа на доставку по промокоду: ${promocodeDeliveryMinPrice} ₽`,
                });
            }
        } else if (
            selfDeliveryAvailable &&
            (typeDelivery === "self" || typeDelivery === "inRestoraunt") &&
            promocodeSelfDeliveryMinPrice > cartWithoutPromocodeProduct
        ) {
            status = "error";
            alert = `Промокод отменен, т.к. действует при заказе на самовывоз на сумму от 
                    ${promocodeSelfDeliveryMinPrice} ₽.`;
            if (promocode.type === "fixed_product") {
                errors.push({
                    code: "minPrice",
                    message: `Минимальная сумма заказа  на самовывоз без учёта товара по промокоду: ${promocodeSelfDeliveryMinPrice} ₽`,
                });
            } else {
                errors.push({
                    code: "minPrice",
                    message: `Минимальная сумма заказа на самовывоз по промокоду: ${promocodeSelfDeliveryMinPrice} ₽`,
                });
            }
        }

        // Проверка максимальной суммы заказа
        const promocodeDeliveryMaxPrice = parseInt(promocode.coupon_max_price);
        const promocodeSelfDeliveryMaxPrice = parseInt(
            promocode.coupon_selfdelivery_max_price
        );

        if (
            cartWithoutPromocodeProduct > promocodeDeliveryMaxPrice &&
            cartWithoutPromocodeProduct > promocodeSelfDeliveryMaxPrice
        ) {
            status = "error";

            if (promocodeDeliveryMaxPrice === promocodeSelfDeliveryMaxPrice) {
                alert =
                    "Промокод отменен, т.к. действует при заказе на сумму до " +
                    promocodeDeliveryMaxPrice +
                    " ₽";
                if (promocode.type === "fixed_product") {
                    errors.push({
                        code: "minPrice",
                        message:
                            "Максимальная сумма заказа без учёта товара по промокоду " +
                            promocodeDeliveryMaxPrice +
                            " ₽",
                    });
                } else {
                    errors.push({
                        code: "minPrice",
                        message:
                            "Максимальная сумма заказа c промокодом " +
                            promocodeDeliveryMaxPrice +
                            " ₽",
                    });
                }
            } else {
                let newMessage = "";

                if (promocode.type === "fixed_product") {
                    newMessage +=
                        "Максимальная сумма заказа без учёта товара по промокоду: ";
                } else {
                    newMessage += "Максимальная сумма заказа по промокоду: ";
                }

                if (deliveryAvailable && selfDeliveryAvailable) {
                    newMessage += `${promocodeDeliveryMaxPrice} ₽ на доставку и ${promocodeSelfDeliveryMaxPrice} ₽ на самовывоз`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму до 
                    ${promocodeDeliveryMaxPrice} ₽. на доставку и от ${promocodeSelfDeliveryMaxPrice} ₽ на самовывоз`;
                } else if (deliveryAvailable) {
                    newMessage += `${promocodeDeliveryMaxPrice} ₽`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму до 
                    ${promocodeDeliveryMaxPrice} ₽. на доставку`;
                } else if (selfDeliveryAvailable) {
                    newMessage += `${promocodeSelfDeliveryMaxPrice} ₽`;
                    alert = `Промокод отменен, т.к. действует при заказе на сумму до ${promocodeSelfDeliveryMaxPrice} ₽ на самовывоз`;
                }

                errors.push({
                    code: "minPrice",
                    message: newMessage,
                });
            }
        } else if (
            deliveryAvailable &&
            typeDelivery === "delivery" &&
            cartWithoutPromocodeProduct > promocodeDeliveryMaxPrice
        ) {
            status = "error";
            alert = `Промокод отменен, т.к. действует при заказе на доставку на сумму до 
                    ${promocodeDeliveryMaxPrice} ₽.`;
            if (promocode.type === "fixed_product") {
                errors.push({
                    code: "minPrice",
                    message: `Максимальная сумма заказа на доставку без учёта товара по промокоду: ${promocodeDeliveryMaxPrice} ₽`,
                });
            } else {
                errors.push({
                    code: "minPrice",
                    message: `Максимальная сумма заказа на доставку по промокоду: ${promocodeDeliveryMaxPrice} ₽`,
                });
            }
        } else if (
            selfDeliveryAvailable &&
            (typeDelivery === "self" || typeDelivery === "inRestoraunt") &&
            cartWithoutPromocodeProduct > promocodeSelfDeliveryMaxPrice
        ) {
            status = "error";
            alert = `Промокод отменен, т.к. действует при заказе на самовывоз на сумму до 
                    ${promocodeSelfDeliveryMaxPrice} ₽.`;
            if (promocode.type === "fixed_product") {
                errors.push({
                    code: "minPrice",
                    message: `Максимальная сумма заказа на самовывоз без учёта товара по промокоду: ${promocodeSelfDeliveryMaxPrice} ₽`,
                });
            } else {
                errors.push({
                    code: "minPrice",
                    message: `Максимальная сумма заказа на самовывоз по промокоду: ${promocodeSelfDeliveryMaxPrice} ₽`,
                });
            }
        }

        return {
            alert,
            status,
            errors,
        };
    }

    return false;
};

export const _isCategoryDisabled = (category) => {
    let disabled = false;
    let message = "";

    if (!category.useTimeLimit) {
        return { disabled, message };
    }

    message = "Товары из данной категории можно заказать";

    if (category.timeLimitStart && category.timeLimitEnd) {
        message += ` c ${category.timeLimitStart} до ${category.timeLimitEnd}`;
    }

    if (category.days && category.days.length) {
        const localDaysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
        const availableDays = category.days
            .map((day, index) => {
                if (day === 1) {
                    return localDaysOfWeek[index];
                } else {
                    return null;
                }
            })
            .filter((el) => el)
            .join(", ");
        message += ` в ${availableDays}`;
    }

    // Проверяем на доступность по времени
    if (
        category.timeLimitStart &&
        category.timeLimitEnd &&
        !_checkWorkingInterval(category.timeLimitStart, category.timeLimitEnd)
    ) {
        return { disabled: true, message };
    }

    // Проверяем на доступность по дням недели
    if (category.days && category.days.length) {
        const currentDayOfWeek =
            getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;
        if (category.days[currentDayOfWeek] == 0) {
            return { disabled: true, message };
        }
    }

    return { disabled, message };
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === "/search") {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant", // Optional if you want to skip the scrolling animation
            });
        }
    }, [pathname]);

    return null;
};

// Функция для проверки функционала сайта по времени
// Принимает две строки формата "HH:MM"
export const _checkWorkingInterval = (
    startTime,
    endTime,
    checkDate = new Date()
) => {
    if (!startTime || !endTime) {
        return true;
    }

    const isTimeAfterMidnight =
        parseInt(startTime.slice(0, 2)) > parseInt(endTime.slice(0, 2));

    const timeLimitStart = set(new Date(), {
        hours: startTime.slice(0, 2),
        minutes: startTime.slice(3, 5),
        seconds: 0,
        milliseconds: 0,
    });

    const timeLimitEnd = set(new Date(), {
        hours: isTimeAfterMidnight ? 23 : endTime.slice(0, 2),
        minutes: isTimeAfterMidnight ? 59 : endTime.slice(3, 5),
        seconds: 0,
        milliseconds: 0,
    });

    const timeLimitEndAfterMidnight = set(new Date(), {
        hours: endTime.slice(0, 2),
        minutes: endTime.slice(3, 5),
        seconds: 0,
        milliseconds: 0,
    });

    let isWithinTimeInterval = false;

    let isWithinAfterMidnightInterval = false;

    try {
        if (
            isWithinInterval(checkDate, {
                start: timeLimitStart,
                end: timeLimitEnd,
            })
        ) {
            isWithinTimeInterval = true;
        }
    } catch (error) {
        isWithinTimeInterval = true;
        console.log(`${error.message}, Something wrong in time interval`);
    }

    try {
        if (
            isTimeAfterMidnight &&
            isWithinInterval(checkDate, {
                start: startOfDay(new Date()),
                end: timeLimitEndAfterMidnight,
            })
        ) {
            isWithinAfterMidnightInterval = true;
        }
    } catch (error) {
        console.log(`${error.message}, Something wrong in time interval`);
    }
    if (isWithinTimeInterval || isWithinAfterMidnightInterval) {
        return true;
    } else {
        return false;
    }
};

export const _checkAutoDiscount = ({
    discount,
    cartProducts,
    cartTotal,
    promocode,
    bonusProduct,
    typeDelivery,
    preorderDate,
    usedBonuses,
}) => {
    const cartProductsArray = Object.values(cartProducts);

    // Проверяем включена ли автоскидка
    if (discount.status !== "on") {
        return false;
    }

    // Проверка платформы
    if (discount.platforms) {
        const currentPlatform = _getPlatform();
        if (discount.platforms[currentPlatform] !== "on") {
            return false;
        }
    }

    // Проверка на возможность использовать с промокодом
    if (promocode.code && discount.allowPromocode !== "on") {
        return false;
    }

    // Проверка возможность использовать с бонусными товарами
    if (bonusProduct.id && discount.allowBonusProduct !== "on") {
        return false;
    }

    // Проверка возможность использовать с бонусами
    if (usedBonuses && discount.allowUserBonuses !== "on") {
        return false;
    }

    // Определяем время заказа
    const currentTime = preorderDate ? preorderDate : new Date();

    // Проверка даты
    if (discount.strStart && discount.strStop) {
        const discountStartDate = parse(
            discount.strStart,
            "yyyy-MM-dd",
            new Date()
        );

        const discountEndDate = parse(
            discount.strStop,
            "yyyy-MM-dd'T'HH:mm",
            new Date()
        );

        try {
            if (
                !isWithinInterval(currentTime, {
                    start: discountStartDate,
                    end: discountEndDate,
                })
            ) {
                return false;
            }
        } catch (error) {
            console.log(
                `${error.message}, Something wrong in discount dates interval`
            );
            return false;
        }
    }

    // Проверка категорий (только для фиксированной скидки)
    if (discount.categories?.length && discount.type === "fixed") {
        // Проверяем есть ли в корзине запрещенная категория
        if (discount.excludeCategories) {
            let hasExcludeCategory = false;
            cartProductsArray.forEach((product) => {
                if (product.items[0].id == promocode.promocodeProducts?.id) {
                    return;
                }
                if (
                    product.items[0].categories?.some((id) =>
                        discount.categories.includes(id)
                    )
                ) {
                    hasExcludeCategory = true;
                }
            });

            if (hasExcludeCategory) {
                return false;
            }
        } else {
            // Проверяем есть ли в корзине что-то кроме разрешенных категорий
            let notInCategory = false;
            cartProductsArray.forEach((product) => {
                if (product.items[0].id == promocode.promocodeProducts?.id) {
                    return;
                }
                if (
                    !product.items[0].categories?.some((id) =>
                        discount.categories.includes(id)
                    )
                ) {
                    notInCategory = true;
                }
            });

            if (notInCategory) {
                return false;
            }
        }
    }

    // Проверка на товары по скидке
    if (discount.excludeSaleProduct !== "yes" && discount.type === "fixed") {
        let hasSaleProduct = false;
        cartProductsArray.forEach((product) => {
            if (
                product.items[0].options._sale_price ||
                product.items[0].variant?._sale_price
            ) {
                hasSaleProduct = true;
            }
        });
        if (hasSaleProduct) {
            return false;
        }
    }

    // Проверка на применение хотя бы к одному товару (только для процентной скидки)
    if (discount.type === "percent") {
        const discountedProduct = Object.values(cartProducts).find(
            (cartProduct) =>
                checkProductForAutoDiscount(discount, cartProduct.items[0])
        );
        if (!discountedProduct) {
            return false;
        }
    }

    // Проверка времени
    if (discount.start_time && discount.stop_time) {
        const isTimeAfterMidnight =
            parseInt(discount.start_time.slice(0, 2)) >
            parseInt(discount.stop_time.slice(0, 2));

        const discountStartTime = set(currentTime, {
            hours: parseInt(discount.start_time.slice(0, 2)),
            minutes: parseInt(discount.start_time.slice(3)),
            seconds: 0,
            milliseconds: 0,
        });
        const discountEndTime = set(currentTime, {
            hours: isTimeAfterMidnight
                ? 23
                : parseInt(discount.stop_time.slice(0, 2)),
            minutes: isTimeAfterMidnight
                ? 59
                : parseInt(discount.stop_time.slice(3)),
            seconds: 0,
            milliseconds: 0,
        });

        const discountAfterMidnightEndTime = set(currentTime, {
            hours: parseInt(discount.stop_time.slice(0, 2)),
            minutes: parseInt(discount.stop_time.slice(3)),
            seconds: 0,
            milliseconds: 0,
        });

        const isWithinDiscountTime = () => {
            try {
                return isWithinInterval(currentTime, {
                    start: discountStartTime,
                    end: discountEndTime,
                });
            } catch (error) {
                console.log(
                    `${error.message}, Something wrong in discount time interval`
                );
                return false;
            }
        };

        const isWithinAfterMidnightTime = () => {
            try {
                return isWithinInterval(currentTime, {
                    start: startOfDay(currentTime),
                    end: discountAfterMidnightEndTime,
                });
            } catch (error) {
                console.log(
                    `${error.message}, Something wrong in discount after midnight interval`
                );
                return false;
            }
        };

        const timeStatus =
            isWithinDiscountTime() ||
            (isTimeAfterMidnight && isWithinAfterMidnightTime());

        if (!timeStatus) {
            return false;
        }
    }

    // Проверка дней недели
    if (discount.days && discount.days.length === 7) {
        const todayDayOfWeek =
            getDay(currentTime) === 0 ? 6 : getDay(currentTime) - 1;
        const isDiscountAvailable = discount.days[todayDayOfWeek] === "1";
        if (!isDiscountAvailable) {
            return false;
        }
    }

    // Проверка типа доставки
    if (typeDelivery) {
        if (
            discount.typeDelivery === "delivery" &&
            typeDelivery !== "delivery"
        ) {
            return false;
        }
        if (
            discount.typeDelivery === "self" &&
            typeDelivery !== "self" &&
            typeDelivery !== "inRestoraunt"
        ) {
            return false;
        }
    }

    // Проверка минимальной суммы заказа
    const discountDeliveryMinPrice = parseInt(discount.deliveryMinOrderTotal);
    const discountSelfDeliveryMinPrice =
        discount.selfDeliveryMinOrderTotal === ""
            ? discountDeliveryMinPrice
            : parseInt(discount.selfDeliveryMinOrderTotal);

    if (promocode.type === "fixed_product") {
        cartTotal =
            cartTotal -
            parseFloat(promocode.promocodeProducts.options._price) +
            parseFloat(promocode.productPrice);
    }

    if (typeDelivery === "delivery" && discountDeliveryMinPrice > cartTotal) {
        return false;
    } else if (
        (typeDelivery === "self" || typeDelivery === "inRestoraunt") &&
        discountSelfDeliveryMinPrice > cartTotal
    ) {
        return false;
    }

    return true;
};

//Функция для определения есть ли в корзине товар по скидке
export const _cartHasDiscountProduct = (cartProducts) => {
    return cartProducts.find((product) => {
        if (
            product.items[0].options._sale_price ||
            product.items[0].variant?._sale_price
        ) {
            return true;
        }
    });
};

export const clearVKStorage = (key = "all") => {
    if (key === "all") {
        bridge
            .send("VKWebAppStorageGetKeys", {
                count: 1000,
                offset: 0,
            })
            .then((data) => {
                console.log(data);
                data.keys.forEach((key) => {
                    bridge.send("VKWebAppStorageSet", {
                        key,
                        value: "",
                    });
                });
            });
    } else {
        bridge.send("VKWebAppStorageSet", {
            key,
            value: "",
        });
    }
};

export const checkProductForAutoDiscount = (autoDiscount, product) => {
    // Если скидка только на выбранные категории
    if (autoDiscount.categories.length && !autoDiscount.excludeCategories) {
        if (
            !product.categories?.some((id) =>
                autoDiscount.categories.includes(id)
            )
        ) {
            return false;
        }
    }

    // Если скидка на все, кроме исключенных категорий
    if (
        autoDiscount.categories.length &&
        autoDiscount.excludeCategories === "yes"
    ) {
        if (
            product.categories?.some((id) =>
                autoDiscount.categories.includes(id)
            )
        ) {
            return false;
        }
    }

    // Если скидка не действует на товары по скидке
    if (
        autoDiscount.excludeSaleProduct !== "yes" &&
        ((product.options._sale_price &&
            parseInt(product.options._regular_price) >
                parseInt(product.options._sale_price)) ||
            product.variant?._sale_price)
    ) {
        return false;
    }

    return true;
};

export const _isObjEmpty = (obj) => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
};

export const _isAuthEnabled = (config) => {
    //Проверка на авторизацию по номеру телефона
    if (config.CONFIG_auth_type !== "noauth") {
        return true;
    }

    //Проверка на авторизацию ВК
    if (
        config.CONFIG_vk_auth_appId &&
        config.CONFIG_vk_auth_status === "active"
    ) {
        return true;
    }

    return false;
};

// Функция для проверки функционала сайта по дате и времени
// Принимает две строки формата "DD.MM.YYYY HH:MM"
// resultOnError - отвечает за результат проверки при выбросе ошибки
export const _checkDateInterval = (
    startDateStr,
    endDateStr,
    resultOnError = false,
    checkDate = new Date()
) => {
    const startDate = set(new Date(), {
        year: startDateStr.slice(6, 10),
        month: parseInt(startDateStr.slice(3, 5)) - 1,
        date: startDateStr.slice(0, 2),
        hours: startDateStr.slice(11, 13),
        minutes: startDateStr.slice(14),
        seconds: 0,
        milliseconds: 0,
    });

    const endDate = set(new Date(), {
        year: endDateStr.slice(6, 10),
        month: parseInt(endDateStr.slice(3, 5)) - 1,
        date: endDateStr.slice(0, 2),
        hours: endDateStr.slice(11, 13),
        minutes: endDateStr.slice(14),
        seconds: 0,
        milliseconds: 0,
    });

    try {
        return isWithinInterval(checkDate, {
            start: startDate,
            end: endDate,
        });
    } catch (error) {
        console.log(`${error.message}, Something wrong in _checkDateInterval`);
        return resultOnError;
    }
};

export const _isPromocodesEnabled = (config) => {
    if (config.CONFIG_disable_promocodes === "on") {
        if (
            config.CONFIG_disable_promocodes_start_date &&
            config.CONFIG_disable_promocodes_end_date
        ) {
            return !_checkDateInterval(
                config.CONFIG_disable_promocodes_start_date,
                config.CONFIG_disable_promocodes_end_date,
                true
            );
        } else {
            return false;
        }
    }
    return true;
};

export const priceToLocale = (price) => {
    if (price === null || price === undefined) return null;
    if (price < 0) {
        price = -price;
    }
    return price.toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
};

export const getNearestBonusExpirationDate = () => {
    const getExpirationDate = (expirationMonth) => {
        const month = getMonth(new Date());
        const year = getYear(new Date());
        return set(new Date(), {
            year: month === 11 ? year + 1 : year,
            month: expirationMonth,
            date: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
        });
    };

    const bonusExpirationDates = [
        getExpirationDate(2),
        getExpirationDate(5),
        getExpirationDate(8),
        getExpirationDate(11),
    ];

    return bonusExpirationDates.find((el) => isBefore(new Date(), el));
};
