import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    addPromocode,
    removePromocode,
    addBonusProductToCart,
    setConditionalPromocode,
    clearConditionalPromocode,
    changePromocodeProductPrice,
    completelyRemovePromocode,
} from "../redux/actions/cart";
import { updateAlerts } from "../redux/actions/systemAlerts";
import {
    setDeliveryZone,
    setOpenDeliveryModal,
} from "../redux/actions/deliveryAddressModal";
import { login } from "../redux/actions/user";
import { getItemTotalPrice } from "../redux/reducers/cart";
import useTownNavigate from "../hooks/useTownNavigate";
import { YMaps } from "react-yandex-maps";
import {
    Alert,
    Button,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    Select,
    TextField,
    ToggleButtonGroup,
    ToggleButton,
    Skeleton,
    Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
    BeforePaymentModal,
    CheckoutProduct,
    Footer,
    Header,
    DeliveryAddressModal,
    UserAddressesList,
    Promocode,
    BootstrapTooltip,
    CheckoutConfirmButtons,
    CheckoutNewAddressForm,
    CheckoutDeliveryResult,
    CheckoutBonusProduct,
    CheckoutTypeDelivery,
    CheckoutSelfDeliveryAddresses,
    CheckoutInRestorauntAddresses,
    PromocodeCheckoutProduct,
} from "../components";
import {
    _checkPromocode,
    _declension,
    _isMobile,
    _getDomain,
    _getPlatform,
    _isObjEmpty,
    _isAuthEnabled,
    _isPromocodesEnabled,
    _clone,
} from "../components/helpers.js";
import CheckoutFreeAddons from "../components/Product/CheckoutFreeAddons";
import PreorderForm from "../components/Product/PreorderForm";
import {
    getHours,
    getMinutes,
    set,
    setDayOfYear,
    addDays,
    format,
    getDay,
    isAfter,
} from "date-fns";
import { ReactComponent as WalletSvg } from "../img/wallet.svg";
import { ReactComponent as CreditCardSvg } from "../img/credit-card.svg";
import { ReactComponent as OnlineCreditCardSvg } from "../img/online-credit-card.svg";
import "../css/checkout.css";
import useAutoDiscount from "../hooks/useAutoDiscount";
import useBonuses from "../hooks/useBonuses";
import CheckoutUserBonuses from "../components/CheckoutUserBonuses";
import CheckoutAddressPanelSkeleton from "../components/Skeletons/CheckoutAddressPanelSkeleton";
import useApiRequests from "../hooks/useApiRequests";

const formatingStrPhone = (inputNumbersValue) => {
    var formattedPhone = "";
    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] === "9")
            inputNumbersValue = "7" + inputNumbersValue;
        var firstSymbols = inputNumbersValue[0] === "8" ? "8" : "+7";
        formattedPhone = firstSymbols + " ";
        if (inputNumbersValue.length > 1) {
            formattedPhone += "(" + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
            formattedPhone += ") " + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
            formattedPhone += "-" + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
            formattedPhone += "-" + inputNumbersValue.substring(9, 11);
        }
    } else {
        formattedPhone = "+" + inputNumbersValue.substring(0, 16);
    }
    return formattedPhone;
};

const getNumbersValue = function (input) {
    return input.replace(/\D/g, "");
};

export default function Checkout() {
    const dispatch = useDispatch();
    const { getRequestAPI, postRequestAPI } = useApiRequests();

    const {
        user,
        config,
        configStatus,
        cart,
        cartProducts,
        items,
        categories,
        promocode,
        conditionalPromocode,
        promocodeProducts,
        userCartBonusProduct,
        cartSubTotalPrice,
        cartTotalPrice,
        gateways,
        isDeliveryHidden,
    } = useSelector(({ user, config, cart, gateways, orderTime, products }) => {
        return {
            user: user.user,
            config: config.data,
            configStatus: config.status,
            gateways: gateways.gateways,
            cart: cart,
            cartProducts: cart.items,
            items: products.items,
            categories: products.categories,
            promocode: cart.promocode,
            conditionalPromocode: cart.conditionalPromocode,
            promocodeProducts: cart.promocodeProducts,
            userCartBonusProduct: cart.bonusProduct,
            cartTotalPrice: cart.totalPrice,
            cartSubTotalPrice: cart.subTotalPrice,
            isDeliveryHidden:
                config.data.CONFIG_checkout_hide_delivery_price === "active",
        };
    });
    const { deliveryZone } = useSelector(
        (state) => state.deliveryAddressModal,
        shallowEqual
    );

    const { navigateTo } = useTownNavigate();
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState(true);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState(user.name ? user.name : "");
    const [userPhone, setUserPhone] = useState(
        user.phone ? formatingStrPhone(user.phone) : ""
    );
    const [typeDelivery, setTypeDelivery] = useState(
        config.CONFIG_type_receiving_selfdelivery_status === "active" &&
            promocode &&
            promocode.typeDelivery === "self"
            ? "self"
            : config.CONFIG_type_receiving_delivery_status === "active"
            ? "delivery"
            : config.CONFIG_type_receiving_selfdelivery_status === "active"
            ? "self"
            : config.CONFIG_type_receiving_inRestoraunt_status === "active"
            ? "inRestoraunt"
            : "self"
    );
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [selfDeliveryAddress, setSelfDeliveryAddress] = useState("main");
    const [activeGateway, setActiveGateway] = useState(
        Array.isArray(gateways) && gateways.length ? gateways[0].id : false
    );
    const [preorderDate, setPreorderDate] = useState(null);
    const [preorderTime, setPreorderTime] = useState("");
    const [asSoonAsPosible, setAsSoonAsPosible] = useState(false);
    const [newUserAddressArea, setNewUserAddressArea] = useState("");
    const [newUserAddressStreet, setNewUserAddressStreet] = useState("");
    const [newUserAddressHome, setNewUserAddressHome] = useState("");
    const [newUserAddressPorch, setNewUserAddressPorch] = useState("");
    const [newUserAddressFloor, setNewUserAddressFloor] = useState("");
    const [newUserAddressApartment, setNewUserAddressApartment] = useState("");
    const [newUserAddressCoordinates, setNewUserAddressCoordinates] =
        useState("");
    const [newUserAddressKladrId, setNewUserAddressKladrId] = useState("");
    const [commentOrder, setCommentOrder] = useState("");
    const [usedBonuses, setUsedBonuses] = useState(0);
    const [countUsers, setCountUsers] = useState(1);
    const [moneyBack, setMoneyBack] = useState("");
    const [dontRecall, setDontRecall] = useState(false);
    const [openBeforePaymentModal, setOpenBeforePaymentModal] = useState(false);
    const [yandexApiError, setYandexApiError] = useState(false);
    const [choosenAddress, setChoosenAddress] = useState(null);
    const [iikoStreets, setIikoStreets] = useState([]);
    const [iikoError, setIikoError] = useState(false);

    const { autoDiscountAmount, autoDiscount } = useAutoDiscount({
        typeDelivery,
        preorderDate,
        usedBonuses,
    });

    // Обновляем данные о пользователе (адреса, бонусы)
    useEffect(() => {
        if (user.token && user.phone) {
            const queryParams = "&token=" + user.token + "&phone=" + user.phone;

            getRequestAPI("checkLogin", queryParams, { mode: "no-cors" }).then(
                (resp) => {
                    if (resp.data.status === "success") {
                        dispatch(login(resp.data.user));
                    }
                }
            );
        }
    }, []);

    useEffect(() => {
        if (
            promocode.type === "fixed_product" &&
            promocode.productSelfDeliveryPrice
        ) {
            dispatch(changePromocodeProductPrice(typeDelivery));
        }
        return () => {
            if (
                promocode.type === "fixed_product" &&
                promocode.productSelfDeliveryPrice &&
                typeDelivery !== "delivery"
            ) {
                dispatch(changePromocodeProductPrice("delivery"));
            }
        };
    }, [typeDelivery, promocode]);

    useEffect(() => {
        if (config.useStreetsAutoComplete === "active") {
            getRequestAPI("getIikoStreets").then((resp) => {
                if (
                    resp.data.status === "success" &&
                    resp.data.streets &&
                    resp.data.streets.length
                ) {
                    setIikoStreets(resp.data.streets);
                } else {
                    setIikoError(true);
                }
            });
        }
    }, [config.useStreetsAutoComplete]);

    useEffect(() => {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
        }
        window.scrollTo(0, 0);
        return () => {
            window.history.scrollRestoration = "auto";
        };
    }, []);

    useEffect(() => {
        if (config.CONFIG_free_products_program_status !== "on") {
            dispatch(addBonusProductToCart({}));
        }
    }, [config.CONFIG_free_products_program_status]);

    useEffect(() => {
        if (config.deliveryZones?.deliveryPriceType !== "areaPrice") {
            dispatch(setDeliveryZone(null));
        }
    }, [config.deliveryZones?.deliveryPriceType]);

    useEffect(() => {
        if (typeDelivery === "delivery") {
            if (user.addresses && user.addresses.length) {
                if (
                    config.deliveryZones?.deliveryPriceType === "areaPrice" &&
                    !yandexApiError
                ) {
                    const addressWithCoordinates = user.addresses.findIndex(
                        (el) => el.coordinates
                    );
                    if (addressWithCoordinates >= 0) {
                        setDeliveryAddress(addressWithCoordinates);
                        setChoosenAddress(
                            user.addresses[addressWithCoordinates]
                        );
                    } else {
                        setDeliveryAddress("new");
                    }
                } else {
                    if (
                        config.useStreetsAutoComplete === "active" &&
                        !iikoError
                    ) {
                        const addressWithIikoStreetIndex =
                            user.addresses.findIndex((el) =>
                                iikoStreets.includes(el.street)
                            );
                        if (addressWithIikoStreetIndex >= 0) {
                            setDeliveryAddress(addressWithIikoStreetIndex);
                        } else {
                            setDeliveryAddress("new");
                        }
                    } else {
                        setDeliveryAddress(0);
                    }
                }
            } else {
                setDeliveryAddress("new");
            }
        }
    }, [
        typeDelivery,
        config.deliveryZones?.deliveryPriceType,
        user.addresses,
        config.useStreetsAutoComplete,
        iikoStreets,
        iikoError,
    ]);

    const handlePreorderDateChange = (date) => {
        if (date === "Как можно скорее") {
            setPreorderDate(new Date());
            setAsSoonAsPosible(date);
            return;
        } else {
            setAsSoonAsPosible(false);
        }
        if (preorderTime) {
            setPreorderDate(
                set(date, {
                    hours: getHours(preorderTime),
                    minutes: getMinutes(preorderTime),
                    seconds: 0,
                    milliseconds: 0,
                })
            );
        }
        if (!preorderTime) {
            if (date) {
                setPreorderDate(date);
            } else {
                setPreorderDate(null);
            }
        }
    };

    const handlePreorderTimeChange = (time) => {
        setPreorderTime(time);
        if (time && preorderDate) {
            const updatedPreorderDate = set(preorderDate, {
                hours: getHours(new Date(time)),
                minutes: getMinutes(new Date(time)),
                seconds: 0,
                milliseconds: 0,
            });
            setPreorderDate(updatedPreorderDate);
        } else if (time) {
            const newPreorderDate = addDays(new Date(), 1);

            const updatedPreorderDate = set(newPreorderDate, {
                hours: getHours(new Date(time)),
                minutes: getMinutes(new Date(time)),
                seconds: 0,
                milliseconds: 0,
            });
            setPreorderDate(updatedPreorderDate);
        }
    };

    const handleChangeName = (e) => {
        setUserName(e.target.value);
    };

    const handleChooseSelfDeliveryAddress = (e, value) => {
        setSelfDeliveryAddress(value);
    };

    const handleChooseDeliveryAddress = (e, index) => {
        setDeliveryAddress(index);
        setChoosenAddress(user.addresses[index]);
    };

    const onYandexApiError = () => {
        setYandexApiError(true);
        dispatch(setDeliveryZone(null));
    };

    const getOrderDeliveryPrice = () => {
        if (typeDelivery === "delivery") {
            if (
                config.deliveryZones?.deliveryPriceType === "areaPrice" &&
                deliveryZone &&
                !yandexApiError
            ) {
                let localCartTotalPrice = cartTotalPrice - autoDiscountAmount;
                // Вычитаем из суммы заказа бонусы, если не включена настройка
                if (
                    ((config.bonusProgramm?.status &&
                        config.bonusProgramm?.paymentIgnoreDeliveryPrice !==
                            "active") ||
                        (config.bonusProgrammStatus &&
                            !config.bonusProgrammPaymentIgnoreDeliveryPrice)) &&
                    usedBonuses
                ) {
                    localCartTotalPrice -= usedBonuses;
                }
                if (
                    !deliveryZone.deliveryPrice ||
                    (deliveryZone.freeDeliveryOrder &&
                        localCartTotalPrice >= deliveryZone.freeDeliveryOrder)
                ) {
                    return 0;
                } else {
                    return parseInt(deliveryZone.deliveryPrice);
                }
            } else if (
                config.deliveryZones?.deliveryPriceType !== "areaPrice" &&
                config.CONFIG_order_delivery_price
            ) {
                return parseInt(config.CONFIG_order_delivery_price);
            }
        }

        return 0;
    };

    const orderDeliveryPrice = getOrderDeliveryPrice();

    const handleChooseZoneDeliveryAddress = (address) => {
        setChoosenAddress(address);
        setNewUserAddressArea(address.area);
        setNewUserAddressStreet(address.street);
        setNewUserAddressHome(address.home);
        setNewUserAddressApartment(address.apartment);
        setNewUserAddressPorch(address.porch);
        setNewUserAddressFloor(address.floor);
        setNewUserAddressCoordinates(address.coordinates);
        setNewUserAddressKladrId(address.kladrId);
    };

    const handleChangeNewUserAddress = (value, field) => {
        switch (field) {
            case "street":
                setNewUserAddressStreet(value);
                break;
            case "home":
                setNewUserAddressHome(value);
                break;
            case "porch":
                setNewUserAddressPorch(value);
                break;
            case "floor":
                setNewUserAddressFloor(value);
                break;
            case "apartment":
                setNewUserAddressApartment(value);
                break;
            default:
                break;
        }
    };
    const handleChangeCommentOrder = (e, value) => {
        setCommentOrder(e.target.value);
    };

    const handleSetActiveGateway = (e, value) => {
        if (!value) {
            return;
        }
        setActiveGateway(value);
    };

    const handleChangeCountUsers = (e, value) => {
        setCountUsers(e.target.value);
    };

    const handleChangeMoneyBack = (e) => {
        const value = e.target.value;
        if (
            parseFloat(value) < 1 ||
            value?.[0] == "0" ||
            !value.match(/^\d+$/)
        ) {
            setMoneyBack("");
        } else {
            setMoneyBack(value);
        }
    };

    const handlePhonePaste = function (e) {
        var input = e.target,
            inputNumbersValue = getNumbersValue(input.value);
        var pasted = e.clipboardData || window.clipboardData;
        if (pasted) {
            var pastedText = pasted.getData("Text");
            if (/\D/g.test(pastedText)) {
                input.value = inputNumbersValue;
                return;
            }
        }
    };

    const handlePhoneInput = function (e) {
        var input = e.target,
            inputNumbersValue = getNumbersValue(input.value),
            selectionStart = input.selectionStart,
            formattedInputValue = "";
        if (!inputNumbersValue) {
            return (input.value = "");
        }
        if (input.value.length !== selectionStart) {
            if (e.data && /\D/g.test(e.data)) {
                input.value = inputNumbersValue;
            }
            return;
        }
        formattedInputValue = formatingStrPhone(inputNumbersValue);
        input.value = formattedInputValue;
        setUserPhone(formattedInputValue);
    };

    const handlePhoneKeyDown = function (e) {
        var inputValue = e.target.value.replace(/\D/g, "");
        if (e.keyCode === 8 && inputValue.length === 1) {
            e.target.value = "";
        }
    };

    const beforePaymentConfirm = () => {
        setOpenBeforePaymentModal(false);
        handleMakeOrder();
    };

    const beforePaymentCancel = () => {
        setOpenBeforePaymentModal(false);
    };

    const handleMakeOrder = () => {
        let currentValidation = true;
        setValidate(true);
        setError(null);

        if (!userName || getNumbersValue(userPhone).length !== 11) {
            currentValidation = false;
            setValidate(false);
            setError("Заполните все обязательные поля");
            return;
        }

        if (
            typeDelivery === "delivery" &&
            deliveryAddress === "new" &&
            ((config.deliveryZones.deliveryPriceType !== "areaPrice" &&
                !newUserAddressStreet) ||
                !newUserAddressHome)
        ) {
            currentValidation = false;
            setValidate(false);
            setError("Заполните все обязательные поля");
            return;
        }

        if (
            typeDelivery === "delivery" &&
            config.deliveryZones.deliveryPriceType === "areaPrice" &&
            !yandexApiError &&
            !deliveryZone
        ) {
            currentValidation = false;
            setValidate(false);
            setError("Выбранный адрес не попадает ни в одну зону доставки");
            return;
        }

        if (!preorderDate || (!preorderTime && !asSoonAsPosible)) {
            currentValidation = false;
            setValidate(false);
            setError("Заполните все обязательные поля");
            return;
        }

        if (currentValidation) {
            setLoading(true);

            // Вставляем товар по промокоду в общий список товаров (для просчётов на бэке)
            const productsCopy = _clone(cartProducts);

            if (promocodeProducts.id) {
                if (productsCopy[promocodeProducts.id]) {
                    productsCopy[promocodeProducts.id].items.push(
                        promocodeProducts
                    );
                    productsCopy[promocodeProducts.id].totalPrice += parseFloat(
                        promocode.productPrice
                    );
                } else {
                    productsCopy[promocodeProducts.id] = {
                        items: [promocodeProducts],
                        totalPrice: parseFloat(promocode.productPrice),
                    };
                }
            }

            postRequestAPI("makeOrder", "", {
                name: userName,
                phone: getNumbersValue(userPhone),
                userPhone: user.phone,
                token: user.token,
                typeDelivery: typeDelivery,
                deliveryAddress: deliveryAddress,
                selfDeliveryAddress: selfDeliveryAddress,
                newUserAddressArea: newUserAddressArea,
                newUserAddressStreet: newUserAddressStreet,
                newUserAddressHome: newUserAddressHome,
                newUserAddressPorch: newUserAddressPorch,
                newUserAddressFloor: newUserAddressFloor,
                newUserAddressApartment: newUserAddressApartment,
                newUserAddressCoordinates: newUserAddressCoordinates,
                newUserAddressKladrId: newUserAddressKladrId,
                deliveryZoneIndex: deliveryZone && deliveryZone.index,
                orderDeliveryPrice: orderDeliveryPrice,
                orderTime: format(preorderDate, "dd.MM.yyyy HH:mm"),
                commentOrder: commentOrder,
                activeGateway: activeGateway,
                countUsers: countUsers,
                promocode: promocode.code,
                promocodeProducts: promocodeProducts,
                moneyBack: moneyBack,
                products: productsCopy,
                bonusProduct: userCartBonusProduct,
                bonuses: usedBonuses,
                dontRecall: dontRecall,
                autoDiscountId: autoDiscount?.id,
                autoDiscountAmount: autoDiscountAmount,
            }).then((resp) => {
                setLoading(false);
                if (resp.data.status === "success") {
                    window.scrollTo(0, 0);
                    navigateTo("/order-complete", {
                        replace: true,
                    });
                } else if (resp.data.status === "need_payment") {
                    if (resp.data.newTab) {
                        navigateTo("/order-pending", {
                            replace: true,
                        });
                    }

                    window.open(
                        resp.data.redirect,
                        resp.data.newTab ? "_blank" : "_self"
                    );
                } else {
                    // Всплывающй алерт
                    setError(resp.data.text);
                    if (resp.data.reason === "promocode_error") {
                        dispatch(completelyRemovePromocode());
                    }
                }
            });
        }
    };

    const handleChangeTypeDelivery = (e, value) => {
        if (value === "self" || value === "inRestoraunt") {
            setTypeDelivery(value);
            setChoosenAddress(null);
            setNewUserAddressStreet("");
            setNewUserAddressHome("");
            setNewUserAddressApartment("");
            setNewUserAddressPorch("");
            setNewUserAddressFloor("");
        } else {
            setTypeDelivery(value);
        }
    };

    const handleChangeUsedBonuses = (value) => {
        setUsedBonuses(value);
    };

    const handleBackToMenu = useCallback(() => {
        window.scrollTo(0, 0);
        navigateTo("/");
    }, []);

    useEffect(() => {
        if (
            Object.keys(promocode).length !== 0 &&
            promocode.constructor === Object &&
            !conditionalPromocode
        ) {
            const resultCheckPromocode = _checkPromocode({
                promocode,
                promocodeProduct: promocodeProducts,
                items: cartProducts,
                cartTotal: cartSubTotalPrice,
                config,
                typeDelivery,
                preorderDate,
                preorderTime,
            });
            if (resultCheckPromocode.status === "error") {
                dispatch(removePromocode());
                dispatch(setConditionalPromocode(promocode));
                dispatch(
                    updateAlerts({
                        open: true,
                        message: resultCheckPromocode.alert,
                    })
                );
            }
        }

        if (conditionalPromocode && !Object.keys(promocode).length) {
            const resultCheckPromocode = _checkPromocode({
                promocode: conditionalPromocode,
                items: cartProducts,
                cartTotal: cartSubTotalPrice,
                config,
                typeDelivery,
                preorderDate,
                preorderTime,
            });
            if (resultCheckPromocode.status !== "error") {
                dispatch(addPromocode(conditionalPromocode));
                dispatch(clearConditionalPromocode());
            }
        }
    }, [
        config,
        promocode,
        typeDelivery,
        conditionalPromocode,
        preorderDate,
        preorderTime,
    ]);

    const userNameProps = {
        error: !userName && !validate ? true : false,
        helperText:
            !userName && !validate ? "Поле обязательно для заполнения" : "",
    };
    const userPhoneProps = {
        error:
            getNumbersValue(userPhone).length !== 11 && !validate
                ? true
                : false,
        helperText:
            getNumbersValue(userPhone).length !== 11 && !validate
                ? "Номер указан неверно"
                : "",
    };
    const streetProps = {
        error:
            !newUserAddressStreet && deliveryAddress === "new" && !validate
                ? true
                : false,
        helperText:
            !newUserAddressStreet && deliveryAddress === "new" && !validate
                ? "Поле обязательно для заполнения"
                : "",
    };
    const homeProps = {
        error:
            !newUserAddressHome && deliveryAddress === "new" && !validate
                ? true
                : false,
        helperText:
            !newUserAddressHome && deliveryAddress === "new" && !validate
                ? "Поле обязательно для заполнения"
                : "",
    };

    const deliveryTextFieldProps = {
        error:
            (!newUserAddressHome || !deliveryZone) && !validate ? true : false,
        helperText:
            !newUserAddressHome && !validate
                ? "Поле обязательно для заполнения"
                : !deliveryZone && !validate
                ? "Выбранный адрес не попадает ни в одну зону доставки"
                : "",
    };

    const preorderFormProps = {
        error:
            !validate && (!preorderDate || (!preorderTime && !asSoonAsPosible)),

        helperText: !validate ? "Выберите дату и время" : "",
    };

    if (user.addresses && user.addresses.length) {
        user.addresses.map((address, index) => {
            if (!address.formate) {
                let formateAddress = address.street + ", д. " + address.home;
                formateAddress += address.porch
                    ? ", под. " + address.porch
                    : "";
                formateAddress += address.floor
                    ? ", этаж " + address.floor
                    : "";
                formateAddress += address.apartment
                    ? ", кв. " + address.apartment
                    : "";
                user.addresses[index].formate = formateAddress;
            }
        });
    }

    const getGatewayIcon = useCallback(
        (gateway) => {
            if (gateway.id) {
                if (gateway.id === "cash") {
                    return <WalletSvg />;
                }
                if (gateway.id === "card") {
                    return <CreditCardSvg />;
                }
                return <OnlineCreditCardSvg />;
            }
            return <div></div>;
        },
        [gateways]
    );
    const getResultTotal = () => {
        let result = cartTotalPrice;
        if (usedBonuses) {
            result -= usedBonuses;
        }
        if (autoDiscount && autoDiscountAmount) {
            result -= autoDiscountAmount;
        }
        if (typeDelivery === "delivery") {
            result += orderDeliveryPrice;
        }
        return result;
    };

    return (
        <>
            <Header />
            <Container
                className="checkout checkout-wrapper"
                sx={{
                    maxWidth: "1328px !important",
                }}
            >
                <h1 className="page-title" lang="ru">
                    Оформление заказа
                </h1>
                <Grid container columnSpacing={5}>
                    <Grid item xs={12} container spacing={5}>
                        <Grid item xs={12} md={7}>
                            <div className="checkout--user">
                                <Grid container spacing={4}>
                                    <Grid item sm={12} md={6} sx={{ width: 1 }}>
                                        <div className="checkout--user-name">
                                            <TextField
                                                size="small"
                                                id="userName"
                                                label="Ваше имя"
                                                onInput={handleChangeName}
                                                value={userName}
                                                sx={{
                                                    width: 1,
                                                    "& .MuiInputBase-root": {
                                                        bgcolor:
                                                            "var(--background-color)",
                                                    },
                                                }}
                                                {...userNameProps}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} md={6} sx={{ width: 1 }}>
                                        <div className="checkout--user-phone">
                                            <TextField
                                                size="small"
                                                id="userPhone"
                                                label="Номер телефона"
                                                onKeyDown={handlePhoneKeyDown}
                                                onInput={handlePhoneInput}
                                                onPaste={handlePhonePaste}
                                                value={userPhone}
                                                sx={{
                                                    width: 1,
                                                    "& .MuiInputBase-root": {
                                                        bgcolor:
                                                            "var(--background-color)",
                                                    },
                                                }}
                                                {...userPhoneProps}
                                                disabled={
                                                    _isAuthEnabled(config) &&
                                                    !(
                                                        config.CONFIG_allow_change_phone ===
                                                            "active" &&
                                                        user.lastTypeAuth ===
                                                            "vk"
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="checkout--delivery">
                                <div className="checkout--delivery-type">
                                    <h3>Как хотите получить заказ?</h3>
                                    <p>Выберите удобный для вас способ.</p>
                                    {_isObjEmpty(config) ? (
                                        <Box sx={{ display: "flex" }}>
                                            <Skeleton
                                                variant="rounded"
                                                width={120}
                                                height={18}
                                                sx={{
                                                    borderRadius: "20px",
                                                    mr: "16px",
                                                }}
                                            />
                                            <Skeleton
                                                variant="rounded"
                                                width={120}
                                                height={18}
                                                sx={{ borderRadius: "20px" }}
                                            />
                                        </Box>
                                    ) : (
                                        <CheckoutTypeDelivery
                                            typeDelivery={typeDelivery}
                                            handleChangeTypeDelivery={
                                                handleChangeTypeDelivery
                                            }
                                        />
                                    )}
                                </div>

                                <Divider
                                    sx={{
                                        my: "20px",
                                        borderColor:
                                            "var(--divider-color-contrast)",
                                    }}
                                />

                                {_isObjEmpty(config) ? (
                                    <CheckoutAddressPanelSkeleton />
                                ) : typeDelivery === "delivery" ? (
                                    <div className="checkout--address-panel">
                                        <h4>Укажите адрес</h4>
                                        {config.CONFIG_delivery_info_text !==
                                            undefined &&
                                            config.CONFIG_delivery_info_text && (
                                                <Alert
                                                    severity="info"
                                                    sx={{ mt: 2, mb: 2 }}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: config.CONFIG_delivery_info_text,
                                                        }}
                                                    ></div>
                                                </Alert>
                                            )}

                                        {yandexApiError ? (
                                            <Alert
                                                severity="error"
                                                sx={{
                                                    mt: 2,
                                                    mb: 2,
                                                    "& .MuiAlert-action": {
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "center",
                                                    },
                                                }}
                                                action={
                                                    _isMobile() ? null : (
                                                        <Button
                                                            variant="button"
                                                            className=" btn--action"
                                                            onClick={() =>
                                                                window.location.reload()
                                                            }
                                                        >
                                                            Обновить
                                                        </Button>
                                                    )
                                                }
                                            >
                                                <div>
                                                    Потеряно соединение с Яндекс
                                                    картами, нажмите кнопку
                                                    «Обновить».
                                                </div>
                                                {_isMobile() ? (
                                                    <Button
                                                        variant="button"
                                                        className=" btn--action"
                                                        onClick={() =>
                                                            window.location.reload()
                                                        }
                                                        sx={{
                                                            width: "100%",
                                                            mt: "8px",
                                                        }}
                                                    >
                                                        Обновить
                                                    </Button>
                                                ) : null}
                                            </Alert>
                                        ) : (
                                            <>
                                                <UserAddressesList
                                                    deliveryAddress={
                                                        deliveryAddress
                                                    }
                                                    handleChooseDeliveryAddress={
                                                        handleChooseDeliveryAddress
                                                    }
                                                    iikoStreets={iikoStreets}
                                                />

                                                {config.deliveryZones
                                                    ?.deliveryPriceType ===
                                                    "areaPrice" &&
                                                deliveryAddress === "new" ? (
                                                    <>
                                                        <TextField
                                                            size="small"
                                                            placeholder="Укажите адрес"
                                                            value={
                                                                choosenAddress?.formate ||
                                                                ""
                                                            }
                                                            multiline
                                                            focused={false}
                                                            fullWidth
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                dispatch(
                                                                    setOpenDeliveryModal(
                                                                        true
                                                                    )
                                                                );
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <span
                                                                        className="text-field__text-adornment"
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation();
                                                                            dispatch(
                                                                                setOpenDeliveryModal(
                                                                                    true
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        {choosenAddress?.formate
                                                                            ? "Изменить"
                                                                            : "Указать"}
                                                                    </span>
                                                                ),
                                                            }}
                                                            sx={{
                                                                "& .MuiOutlinedInput-root":
                                                                    {
                                                                        cursor: "pointer",
                                                                    },
                                                                "& .MuiOutlinedInput-input":
                                                                    {
                                                                        cursor: "pointer",
                                                                    },
                                                            }}
                                                            {...deliveryTextFieldProps}
                                                        />
                                                    </>
                                                ) : null}

                                                {deliveryAddress === "new" &&
                                                    config.deliveryZones
                                                        ?.deliveryPriceType !==
                                                        "areaPrice" && (
                                                        <CheckoutNewAddressForm
                                                            streetProps={
                                                                streetProps
                                                            }
                                                            homeProps={
                                                                homeProps
                                                            }
                                                            newUserAddressStreet={
                                                                newUserAddressStreet
                                                            }
                                                            newUserAddressHome={
                                                                newUserAddressHome
                                                            }
                                                            newUserAddressPorch={
                                                                newUserAddressPorch
                                                            }
                                                            newUserAddressFloor={
                                                                newUserAddressFloor
                                                            }
                                                            newUserAddressApartment={
                                                                newUserAddressApartment
                                                            }
                                                            handleChangeNewUserAddress={
                                                                handleChangeNewUserAddress
                                                            }
                                                            iikoStreets={
                                                                iikoStreets
                                                            }
                                                            iikoError={
                                                                iikoError
                                                            }
                                                        />
                                                    )}
                                            </>
                                        )}
                                    </div>
                                ) : typeDelivery === "self" ? (
                                    <CheckoutSelfDeliveryAddresses
                                        selfDeliveryAddress={
                                            selfDeliveryAddress
                                        }
                                        handleChooseSelfDeliveryAddress={
                                            handleChooseSelfDeliveryAddress
                                        }
                                    />
                                ) : typeDelivery === "inRestoraunt" ? (
                                    <CheckoutInRestorauntAddresses
                                        selfDeliveryAddress={
                                            selfDeliveryAddress
                                        }
                                        handleChooseSelfDeliveryAddress={
                                            handleChooseSelfDeliveryAddress
                                        }
                                    />
                                ) : null}
                            </div>
                            <YMaps
                                query={{
                                    apikey: config.deliveryZones
                                        ? config.deliveryZones.apiKey
                                        : "",
                                }}
                            >
                                {config.deliveryZones?.deliveryPriceType ===
                                    "areaPrice" &&
                                !yandexApiError &&
                                configStatus ? (
                                    <DeliveryAddressModal
                                        choosenAddress={choosenAddress}
                                        onYandexApiError={onYandexApiError}
                                        handleChooseZoneDeliveryAddress={
                                            handleChooseZoneDeliveryAddress
                                        }
                                    />
                                ) : null}
                            </YMaps>

                            {_isObjEmpty(config) ? null : (
                                <PreorderForm
                                    preorderDate={preorderDate}
                                    preorderTime={preorderTime}
                                    handlePreorderDateChange={
                                        handlePreorderDateChange
                                    }
                                    handlePreorderTimeChange={
                                        handlePreorderTimeChange
                                    }
                                    asSoonAsPosible={asSoonAsPosible}
                                    {...preorderFormProps}
                                />
                            )}
                            <div className="checkout--comment-order">
                                <h3>Комментарий к заказу</h3>
                                <TextField
                                    id="commentOrder"
                                    label={
                                        commentOrder.length
                                            ? `Осталось символов: ${
                                                  175 - commentOrder.length
                                              }`
                                            : undefined
                                    }
                                    placeholder="Введите пожелание к заказу"
                                    multiline
                                    maxRows={8}
                                    value={commentOrder}
                                    onInput={handleChangeCommentOrder}
                                    inputProps={{ maxLength: 175 }}
                                    sx={{
                                        width: 1,
                                        "& .MuiInputBase-root": {
                                            bgcolor: "var(--background-color)",
                                        },
                                    }}
                                />
                            </div>
                            {_isMobile() ? null : (
                                <CheckoutConfirmButtons
                                    error={error}
                                    loading={loading}
                                    activeGateway={activeGateway}
                                    typeDelivery={typeDelivery}
                                    deliveryZone={deliveryZone}
                                    yandexApiError={yandexApiError}
                                    handleSetError={(error) => {
                                        setError(error);
                                    }}
                                    handleBackToMenu={handleBackToMenu}
                                    handleMakeOrder={handleMakeOrder}
                                    handleOpenBeforePaymentModal={(value) => {
                                        setOpenBeforePaymentModal(value);
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            md={5}
                            sx={{ width: 1 }}
                            className="checkout--total-wrapper"
                        >
                            <div className={"checkout--total-panel"}>
                                <h3 className="checkout--total-panel--title">
                                    Ваш заказ
                                </h3>

                                <div className="checkout--products">
                                    {/* { Object.keys(cartProducts).map( (key, index) => 
                                    <CheckoutProduct key={cartProducts[key].items[0].id} productCart={cartProducts[key].items[0]} productCount={cartProducts[key].items.length} productTotalPrice={cartProducts[key].totalPrice} />
                                ) } */}

                                    {Object.keys(cartProducts).map(
                                        (key, index) => {
                                            if (
                                                items[key] &&
                                                items[key].type !== undefined &&
                                                items[key].type === "variations"
                                            ) {
                                                return cartProducts[
                                                    key
                                                ].items.map(
                                                    (
                                                        keyVariantProduct,
                                                        indexVariantProduct
                                                    ) => (
                                                        <CheckoutProduct
                                                            key={
                                                                indexVariantProduct
                                                            }
                                                            productIndex={
                                                                indexVariantProduct
                                                            }
                                                            productCart={
                                                                cartProducts[
                                                                    key
                                                                ].items[
                                                                    indexVariantProduct
                                                                ]
                                                            }
                                                            productCount={1}
                                                            productTotalPrice={
                                                                cartProducts[
                                                                    key
                                                                ].totalPrice
                                                            }
                                                        />
                                                    )
                                                );
                                            } else {
                                                const itemsWithoutModificators =
                                                    cartProducts[
                                                        key
                                                    ].items?.filter(
                                                        (el) =>
                                                            !el
                                                                .choosenModificators
                                                                ?.length
                                                    );
                                                const itemsWithoutModificatorsTotal =
                                                    getItemTotalPrice(
                                                        itemsWithoutModificators
                                                    );
                                                let itemWithoutModificatorRendered = false;
                                                return cartProducts[
                                                    key
                                                ].items.map((el, inx) => {
                                                    if (
                                                        el.choosenModificators
                                                            ?.length
                                                    ) {
                                                        return (
                                                            <CheckoutProduct
                                                                key={inx}
                                                                productIndex={
                                                                    inx
                                                                }
                                                                productCart={el}
                                                                productCount={1}
                                                                productTotalPrice={
                                                                    el.options
                                                                        ._promocode_price
                                                                        ? Math.ceil(
                                                                              el
                                                                                  .options
                                                                                  ._promocode_price
                                                                          )
                                                                        : el
                                                                              .options
                                                                              ._price
                                                                }
                                                            />
                                                        );
                                                    } else if (
                                                        !itemWithoutModificatorRendered
                                                    ) {
                                                        itemWithoutModificatorRendered = true;
                                                        return (
                                                            <CheckoutProduct
                                                                key={inx}
                                                                productIndex={
                                                                    inx
                                                                }
                                                                productCart={el}
                                                                productCount={
                                                                    itemsWithoutModificators.length
                                                                }
                                                                productTotalPrice={
                                                                    itemsWithoutModificatorsTotal
                                                                }
                                                            />
                                                        );
                                                    }
                                                });
                                            }
                                        }
                                    )}

                                    {promocodeProducts.id ? (
                                        <PromocodeCheckoutProduct />
                                    ) : null}

                                    {userCartBonusProduct.id && (
                                        <CheckoutBonusProduct />
                                    )}

                                    <CheckoutFreeAddons />
                                </div>

                                {_isPromocodesEnabled(config) && (
                                    <div className="checkout--promocode">
                                        <Promocode
                                            ignoreMinPrice={true}
                                            typeDelivery={typeDelivery}
                                            preorderDate={preorderDate}
                                            preorderTime={preorderTime}
                                        />
                                    </div>
                                )}

                                <hr className="checkout--total-panel--separator" />

                                {cart.discount ||
                                autoDiscountAmount ||
                                (typeDelivery === "delivery" &&
                                    !isDeliveryHidden) ? (
                                    <div className="checkout--subtotal-price">
                                        Сумма заказа
                                        <span className="money">
                                            {cart.subTotalPrice.toLocaleString(
                                                "ru-RU"
                                            )}{" "}
                                            &#8381;
                                        </span>
                                    </div>
                                ) : null}

                                <div className="checkout--total-panel--result">
                                    {promocode.code && cart.discount ? (
                                        <div className="checkout--promocode-total">
                                            <div className="checkout--promocode-name">
                                                Промокод{" "}
                                                <span className="main-color">
                                                    {promocode.code}
                                                </span>
                                            </div>

                                            <span className="money main-color">
                                                -
                                                {cart.discount.toLocaleString(
                                                    "ru-RU"
                                                )}{" "}
                                                &#8381;
                                            </span>
                                        </div>
                                    ) : null}

                                    {autoDiscount && autoDiscountAmount ? (
                                        <div className="checkout--auto-discount-container">
                                            <div className="checkout--auto-discount-name">
                                                <span>Скидка</span>
                                                <BootstrapTooltip
                                                    placement="top"
                                                    enterTouchDelay={0}
                                                    title={autoDiscount.name}
                                                >
                                                    <InfoOutlinedIcon className="checkout--info-icon" />
                                                </BootstrapTooltip>
                                            </div>

                                            <span className="checkout--auto-discount-amount main-color">
                                                -
                                                {autoDiscountAmount.toLocaleString(
                                                    "ru-RU"
                                                )}{" "}
                                                &#8381;
                                            </span>
                                        </div>
                                    ) : null}
                                    {typeDelivery !== "delivery" ||
                                    isDeliveryHidden ||
                                    (config.deliveryZones?.deliveryPriceType ===
                                        "areaPrice" &&
                                        (!deliveryZone ||
                                            yandexApiError)) ? null : (
                                        <CheckoutDeliveryResult
                                            deliveryPrice={orderDeliveryPrice}
                                        />
                                    )}
                                    <Divider
                                        sx={{
                                            my: "20px",
                                            borderColor:
                                                "var(--divider-color-contrast)",
                                        }}
                                        className="checkout-result-total-divider"
                                    />
                                    <div className="result-total">
                                        <span className="price-title">
                                            Итого
                                        </span>
                                        <span className="money">
                                            {getResultTotal().toLocaleString(
                                                "ru-RU"
                                            )}{" "}
                                            &#8381;
                                        </span>
                                    </div>
                                </div>

                                {!!config.bonusProgrammStatus ||
                                config.bonusProgramm?.status === "active" ? (
                                    <CheckoutUserBonuses
                                        usedBonuses={usedBonuses}
                                        handleChangeUsedBonuses={
                                            handleChangeUsedBonuses
                                        }
                                        typeDelivery={typeDelivery}
                                        deliveryZone={deliveryZone}
                                        autoDiscount={autoDiscount}
                                        autoDiscountAmount={autoDiscountAmount}
                                    />
                                ) : null}

                                <hr className="checkout--total-panel--separator" />

                                {_isObjEmpty(config) ? (
                                    <div className="checkout--gateways">
                                        <Skeleton
                                            variant="rounded"
                                            width={"100%"}
                                            height={40}
                                            sx={{
                                                borderRadius: "12px",
                                                mb: "8px",
                                            }}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            width={"100%"}
                                            height={40}
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </div>
                                ) : (
                                    <div className="checkout--gateways">
                                        <h4>Способ оплаты</h4>
                                        {Array.isArray(gateways) &&
                                            !!gateways.length && (
                                                <ToggleButtonGroup
                                                    value={activeGateway}
                                                    onChange={
                                                        handleSetActiveGateway
                                                    }
                                                    orientation="vertical"
                                                    exclusive
                                                    className="checkout--gateways-btn-group"
                                                >
                                                    {gateways.map(
                                                        (key, index) => (
                                                            <ToggleButton
                                                                key={index}
                                                                value={key.id}
                                                                label={
                                                                    key.title
                                                                }
                                                                className="checkout--gateways-btn"
                                                            >
                                                                <div className="checkout--gateways-title-container">
                                                                    <Radio
                                                                        size="small"
                                                                        checked={
                                                                            activeGateway ===
                                                                            key.id
                                                                        }
                                                                    />
                                                                    <div>
                                                                        {
                                                                            key.title
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {getGatewayIcon(
                                                                    key
                                                                )}
                                                            </ToggleButton>
                                                        )
                                                    )}
                                                </ToggleButtonGroup>
                                            )}

                                        <div className="checkout--gateways-inputs">
                                            {config.CONFIG_checkout_hide_count_person ===
                                            "yes" ? null : (
                                                <div className="checkout--gateways-input">
                                                    <b>
                                                        {config.CONFIG_checkout_count_person_name ||
                                                            "Количество персон"}
                                                    </b>
                                                    <Select
                                                        id="count_peoples"
                                                        value={countUsers}
                                                        sx={{
                                                            width: 1,
                                                            mt: 0.5,
                                                        }}
                                                        size="small"
                                                        onChange={
                                                            handleChangeCountUsers
                                                        }
                                                    >
                                                        <MenuItem value={1}>
                                                            1
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            2
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            3
                                                        </MenuItem>
                                                        <MenuItem value={4}>
                                                            4
                                                        </MenuItem>
                                                        <MenuItem value={5}>
                                                            5
                                                        </MenuItem>
                                                        <MenuItem value={6}>
                                                            6
                                                        </MenuItem>
                                                        <MenuItem value={7}>
                                                            7
                                                        </MenuItem>
                                                        <MenuItem value={8}>
                                                            8
                                                        </MenuItem>
                                                        <MenuItem value={9}>
                                                            9
                                                        </MenuItem>
                                                        <MenuItem value={10}>
                                                            10
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            )}

                                            {activeGateway === "cash" && (
                                                <div className="checkout--gateways-input">
                                                    <b>Приготовить сдачу с</b>
                                                    <TextField
                                                        size="small"
                                                        id="money_back"
                                                        value={moneyBack}
                                                        onChange={
                                                            handleChangeMoneyBack
                                                        }
                                                        inputProps={{
                                                            type: "tel",
                                                            min: 1,
                                                            step: 1,
                                                        }}
                                                        sx={{
                                                            width: 1,
                                                            mt: 0.5,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {config.CONFIG_checkout_dont_recall === "on" ? (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={dontRecall}
                                                onChange={() =>
                                                    setDontRecall(!dontRecall)
                                                }
                                                name="gilad"
                                            />
                                        }
                                        label={
                                            config.CONFIG_checkout_dont_recall_text ||
                                            "Не перезванивайте мне"
                                        }
                                    />
                                ) : null}
                            </div>
                        </Grid>
                    </Grid>
                    {_isMobile() ? (
                        <Grid item xs={12} md={7}>
                            <CheckoutConfirmButtons
                                error={error}
                                loading={loading}
                                activeGateway={activeGateway}
                                typeDelivery={typeDelivery}
                                deliveryZone={deliveryZone}
                                yandexApiError={yandexApiError}
                                handleSetError={(error) => {
                                    setError(error);
                                }}
                                handleBackToMenu={handleBackToMenu}
                                handleMakeOrder={handleMakeOrder}
                                handleOpenBeforePaymentModal={(value) => {
                                    setOpenBeforePaymentModal(value);
                                }}
                            />
                        </Grid>
                    ) : null}
                </Grid>

                <div className=""></div>
            </Container>
            {config.CONFIG_order_text_before_payment ? (
                <BeforePaymentModal
                    openModal={openBeforePaymentModal}
                    beforePaymentConfirm={beforePaymentConfirm}
                    beforePaymentCancel={beforePaymentCancel}
                    content={config.CONFIG_order_text_before_payment}
                />
            ) : null}
            <Footer />
        </>
    );
}
